import classes from './Body_frame.module.css';
import Pix from './pix_icon.png';
import resets from './_resets.module.css';
import Web3 from 'web3';
import { Buffer } from "buffer/";
import { forwardRef, useState, useEffect, useRef, useContext } from 'react';
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, USDC_CONTRACT_ADDRESSES, MANTEINENCETOKEN, USDT_CONTRACT_ADDRESSES, POLYGONURL, DEFAULTCOIN} from '../../variaveis';
import { BRLAContractAbi, USDCContractAbi, USDTContractAbi } from '../../utils/abis';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import ListaCoinsRecebe from '../../Components/ListaCoinsRecebe';
import Checked from '../../Components/Transfer/checked.png';
import TetherIcon from '../Swap/0xdac17f958d2ee523a2206206994597c13d831ec7.png'
import ListaCoins from '../../Components/ListaCoins';
import { useTranslation } from 'react-i18next';
import { validaCNPJ, isCPFValid } from '../../Components/Payment/validaTaxId';
import { deparaBank } from '../../Components/Payment/deparaBank';
import ListaCoinsPayment from '../../Components/ListaCoinsPayment';
import QRCode from 'qrcode.react';
function PaymentMobile({ onPixKeyTypeChange, OnPixKeyValid, OnChangeRecebeToken, OnChangeToken}) {


  const [message, setMessage] = useState({ text: '', color: '' });
  const { t, i18n} = useTranslation();
  const [quoteResult, setQuoteResult] = useState(null);
  const [quoteResultFinal, setQuoteResultFinal] = useState(null);


   
  const stopPropagation = (event) => {
    event.stopPropagation();
};

  const bankOptions = Object.entries(deparaBank).map(([bankName, bankCode]) => ({
    value: bankCode,
    label: bankName
}));

// State para armazenar o banco selecionado
const [selectedBank, setSelectedBank] = useState(null);

const handleChange = (selectedOption) => {
  console.log(selectedOption)
  setSelectedBank(selectedOption);
  // Aqui você pode fazer outras ações, como atualizar um estado ou chamar um callback
  console.log(`Banco selecionado:`, selectedOption);
};
 
const [accountType, setAccountType] = useState('checking');

    // Função para lidar com a mudança de seleção do select
    const handleAccountTypeChange = (event) => {
        setAccountType(event.target.value);
    };


  
  const [users, setUsers] = useState([]);
  const [fees, setFees] = useState([]);
  const [balance, setBalance] = useState([]);
  const [balanceUsd, setBalanceUsd] = useState([]);
  const [balanceUsdt, setBalanceUsdt] = useState([]);
  const [pixKeyType, setPixKeyType] = useState(users?.kyc?.documentType === 'CPF' ? 'CPF' : 'CNPJ');
  const [pixKey, setPixKey] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [fullName, setfullName] = useState('');
  const [transferType, settransferType] = useState({
    flag: '',
    abbreviation: 'pix'
  });
  const [pixKeyValid, setPixKeyValid] = useState(false);
  const [erroreq, seterroreq] = useState(false);
  const [currencyType, setcurrencyType] = useState('');
  const [CpfCnpj, setCpfCnpj] = useState('');
  const [Token, setToken] = useState(null);
  const [ValidCpfCnpj, setValidCpfCnpj] = useState(false);
  const [marginLeftValue, setMarginLeftValue] = useState('100px');
  const [marginLeftValueP, setMarginLeftValueP] = useState('70px');
  const [codeoneActive, SetcodeoneActive] = useState(false);
  const [codetwoActive, SetcodetwoActive] = useState(false);
  const [codetreeActive, SetcodetreeActive] = useState(false);
  const [codefourActive, SetcodefourActive] = useState(false);
  const [codefiveActive, SetcodefiveActive] = useState(false);
  const [codesixActive, SetcodesixActive] = useState(false);
 

  const handlePixKeyChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setPixKeyValid(false)
    const inputValue = event.target.value;
    let formattedValue = inputValue;
  
    

    setPixKey(inputValue);
  };

  const handleBranchCodeChange = (event) => {
    const inputValue = event.target.value;  
    setBranchCode(inputValue);
  };

  const handleAccountNumberChange = (event) => {
    const inputValue = event.target.value;  
    setAccountNumber(inputValue);
  };

  const handleFullNameChange = (event) => {
    const inputValue = event.target.value;  
    setfullName(inputValue);
  };



  const handleCpfCnpjChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setValidCpfCnpj(false);
    const inputValue = event.target.value;
    let formattedValue = inputValue;
  
    

    setCpfCnpj(inputValue);
  }; 

  function isValidEmailFormat(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);

  }
  
  
  const handlePixKeyFormat = () => {
    let formattedValue = pixKey;
  
    // Check for CPF format (11 digits)
    if (isCPFValid(pixKey) && /^\d{11}$/.test(pixKey)) {
      formattedValue = `${pixKey.substring(0, 3)}.${pixKey.substring(3, 6)}.${pixKey.substring(6, 9)}-${pixKey.substring(9)}`;
      
    }
  
     // Check for CNPJ format (14 digits)
  if (validaCNPJ(pixKey) && /^\d{14}$/.test(pixKey)) {
    formattedValue = `${pixKey.substring(0, 2)}.${pixKey.substring(2, 5)}.${pixKey.substring(5, 8)}/${pixKey.substring(8, 12)}-${pixKey.substring(12)}`;
    
  }

  

  // Check for Cell Phone format (11 or 12 digits)
  if (/^\d{11,12}$/.test(pixKey) && !isCPFValid(pixKey) && !validaCNPJ(pixKey)) {
    formattedValue = `+55 (${pixKey.substring(0, 2)}) ${pixKey.substring(2, 7)}-${pixKey.substring(7)}`;
    
  }

  if (pixKey.replace(' ','').replace(/-/g, '').length === 32) {
   setPixKeyValid(true);
   setPixKeyType('Chave-aleatoria');
   GetPixInfo(pixKey.replace(' ',''));
   console.log("chave aleatoria")
  }

  if (pixKey.replace('+55','',).replace('(','').replace(')').replace(' ').replace('-','').length === 11) {
    setPixKeyValid(true)
    setPixKeyType('Celular');
    GetPixInfo(pixKey.replace('+55','',).replace('(','').replace(')').replace(' ').replace('-',''))
   }
  
  if (isValidEmailFormat(pixKey.replace(' ',''))) {
    setPixKeyValid(true)
    setPixKeyType('Email');
    GetPixInfo(pixKey.replace(' ',''))
  }


  if (isCPFValid(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''))) {
    setPixKeyValid(true);
    setPixKeyType('CPF');
    setValidCpfCnpj(true);
    GetPixInfo(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));

  }

  if (validaCNPJ(pixKey.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))) {
    setPixKeyValid(true);
    setPixKeyType('CNPJ');
    setValidCpfCnpj(true);
    GetPixInfo(pixKey.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))
  }

    setPixKey(formattedValue);
  };



  const handleCpfCnpjFormat = () => {
    let formattedValue = CpfCnpj;
  
    // Check for CPF format (11 digits)
    if (isCPFValid(CpfCnpj) && /^\d{11}$/.test(CpfCnpj)) {
      formattedValue = `${CpfCnpj.substring(0, 3)}.${CpfCnpj.substring(3, 6)}.${CpfCnpj.substring(6, 9)}-${CpfCnpj.substring(9)}`;
      
    }
  
     // Check for CNPJ format (14 digits)
  if (validaCNPJ(CpfCnpj) && /^\d{14}$/.test(CpfCnpj)) {
    formattedValue = `${CpfCnpj.substring(0, 2)}.${CpfCnpj.substring(2, 5)}.${CpfCnpj.substring(5, 8)}/${CpfCnpj.substring(8, 12)}-${CpfCnpj.substring(12)}`;
    
  }

  if (isCPFValid(CpfCnpj.replace('.','').replace('-','').replace('.','').replace(' ',''))) {
    setValidCpfCnpj(true);
    setCpfCnpj(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));

  }

  if (validaCNPJ(CpfCnpj.replace('.','').replace('-','').replace('.','').replace('/','').replace(' ',''))) {
    setValidCpfCnpj(true);
    setCpfCnpj(pixKey.replace('.','').replace('-','').replace('.','').replace(' ',''));
  }

    setCpfCnpj(formattedValue);
  };

  const handleWalletChange = (event) => {
    setMessage({ text: '', color: '' }); // Limpa qualquer mensagem anterior
    setWalletValue(event.target.value);
    
  };


  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      const parsedUsers = JSON.parse(loadedUsers);
    setUsers(parsedUsers);
    setPixKeyType(parsedUsers.kyc.documentType === 'CPF' ? 'CPF' : 'CNPJ');
    }
 
    const fetchBalance = async () => {
    
   
      // Cria uma nova instância do web3 com o Infura
      const web3 = new Web3(new Web3.providers.HttpProvider(POLYGONURL));

      // Mapeia cada carteira para um objeto que inclui o saldo BRLA
      
          const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[137];
          const USDC_CONTRACT_ADDRESS = USDC_CONTRACT_ADDRESSES[137];
          const USDT_CONTRACT_ADDRESS = USDT_CONTRACT_ADDRESSES[137];
          const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
          const contractUsd = new web3.eth.Contract(USDCContractAbi, USDC_CONTRACT_ADDRESS);
          const contractUsdt = new web3.eth.Contract(USDTContractAbi, USDT_CONTRACT_ADDRESS);
          const balance = await contract.methods.balanceOf(users?.wallets?.evm).call();
          const balanceUsd = await contractUsd.methods.balanceOf(users?.wallets?.evm).call();
          const balanceUsdt = await contractUsdt.methods.balanceOf(users?.wallets?.evm).call();
          setBalance(balance)
          setBalanceUsdt(balanceUsdt);
          setBalanceUsd(balanceUsd);
      
  };

  if (users?.wallets?.evm) {
    fetchBalance();
  }



  }, [users?.wallets?.evm]);
  
  

  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [priceusd, setPriceusd] = useState('');
  const navigate = useNavigate();



  useEffect(() => {
    // Detectar sistema operacional
    const os = window.navigator.platform;
    const userAgent = window.navigator.userAgent;
    const linuxPattern = /Linux/;
    const windowsPattern = /Win/;
    const firefoxPattern = /Firefox/;
  
    // Se o sistema operacional for Linux ou o navegador for Firefox, defina marginLeft para '30px'
    if ( firefoxPattern.test(userAgent)) {
      setMarginLeftValue('20px');
      setMarginLeftValueP('-10px');
    }

    else if (linuxPattern.test(os)) {

      setMarginLeftValue('60px');
      setMarginLeftValueP('30px');
      
    }
  
    // Se o sistema operacional for Windows, defina marginLeft para '100px'
    else {
      setMarginLeftValue('100px');
      setMarginLeftValueP('70px');
    }
  }, []);
  



  const {
    register,
    handleSubmit,
    setFocus,
    control, // Adicione esta linha
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const [mintValue, setMintValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [ishighValue, setIshighvalue] = useState(false);
  const [recebeValue, setRecebeValue] = useState('');
  const [showPopup, setshowPopup]= useState(false);
  const [walletValue, setWalletValue] = useState('');
  const [isInvalidrecebe, setIsInvalidRecebe] = useState(false);

  function debounce(func, delay) {
    let timeout;
    return function(...args) {
      const context = this;
      return new Promise((resolve, reject) => {
        clearTimeout(timeout);
        timeout = setTimeout(async () => {
          try {
            const result = await func.apply(context, args);
            resolve(result);
          } catch (error) {
            reject(error);
          }
        }, delay);
      });
    };
  }

  const debouncedFastQuote = useRef(debounce(async (amount, chain, inputCoin, outputCoin, fixOutput) => {
    const result = await fastQuote(amount, chain, inputCoin, outputCoin, fixOutput);
    setQuoteResult(result);
    return result;
  }, 500));


const [isMintInput, setIsMintInput] = useState(true);

const handleMintInputChange = (e) => {
    let value = e.target.value;
    setIsMintInput(true);
    setMessage('');
 
  setIshighvalue(false);
    // Remove qualquer caractere que não seja número, ponto ou vírgula
    value = value.replace(/[^\d.,]/g, '');
  
    // Adiciona o "R$" apenas para exibição no input
    const displayValue = `${value}`;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setMintValue(displayValue);
     
      setIsInvalid(false);
      return;
    }

    // Remove o "R$" para realizar a validação numérica
    const numericValue = parseFloat(value.replace('R$', '').replace(',', '.'));
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(numericValue)) {
      setMintValue(displayValue);
      
      debouncedFastQuote.current(parseFloat((numericValue * 100).toFixed(0)), selectedToken.chain, selectedToken.abbreviation, 'BRLA' , false);
       

    }
  };
  

  useEffect(() => {
    if (quoteResult) {
      
        if (quoteResult.amountUsd !== undefined) {
          if (isMintInput) {
            if ((parseFloat(quoteResult.amountBrl)) <0 )  {
              setRecebeValue(0)
            }
            else { setRecebeValue((parseFloat(quoteResult.amountBrl) ).toString());
            }
          } else {
            if ((parseFloat(quoteResult.amountUsd)) <0 )  {
              setMintValue(0) } else {
                setMintValue((parseFloat(quoteResult.amountUsd) ).toString());
              }
            
          }
        }

 
    }
  }, [quoteResult, isMintInput]);




 useEffect(() => {
   
  console.log("completed: ", completed)
  }, [completed]);
  

  const handleRecebeInputChange = (e) => {
    let value = e.target.value;
    setIsMintInput(false);
    setMessage('');
   
    // Remove qualquer caractere que não seja número, ponto ou vírgula
    value = value.replace(/[^\d.,]/g, '');
  
    // Adiciona o "R$" apenas para exibição no input
    const displayValue = `${value}`;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setRecebeValue(displayValue);
      setIsInvalidRecebe(false);
      return;
    }
    // Remove o "R$" para realizar a validação numérica
    const numericValue = parseFloat(value.replace('R$', '').replace(',', '.'));
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(numericValue)) {
      
      debouncedFastQuote.current(parseFloat((numericValue * 100).toFixed(0)), selectedToken.chain, selectedToken.abbreviation, 'BRLA' , true);
      }
    
      setRecebeValue(displayValue);
   
  };


  useEffect(() => {

    setCompleted(false);
    setMessage('');
    setRecebeValue('');
    setMintValue('');
    

  }, []);

  useEffect(() => {
    setMessage('');
    if (recebeValue === '') {
      setMintValue('');
      setIsInvalid(false)
    } 

    if (isNaN(mintValue)) {
      setIsInvalid(true)
    }
    

    
    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }

 
    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }


  }, [recebeValue]);

  
  
  useEffect(() => {
    setMessage('');
    if (mintValue === '') {
     
     setRecebeValue('');
      
      
      setIsInvalid(false)
    } 
    if (isNaN(mintValue) || mintValue ==='') {
      setIsInvalid(true)
    }

    
    if (selectedTokenRecebe?.abbreviation==='BRL' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }


    if (selectedTokenRecebe?.abbreviation==='BRLA' && selectedToken?.abbreviation==='USD') {
      setIsInvalid(true);
      if (!(isNaN(mintValue) || isNaN(recebeValue))) {
      setIsInvalid((mintValue) > (balanceUsd / Math.pow(10, 6)) || mintValue==='')};
    }    
    
    
  }, [mintValue]);
  

  
  const [showListaCoins, setShowListaCoins] = useState(false);
  const [showListaCoinsRecebe, setShowListaCoinsRecebe] = useState(false);
  const [showListaChains, setShowListaChains] = useState(false);
  const [showListaTransfer, setShowListaTransfer] = useState(false);
  const ListaCoinsRef = useRef();
  const ListaTransferRef = useRef();
  const ListaCoinsRecebeRef = useRef();

  const buttonRefListaCoinst = useRef(null);
  const buttonRefListaCoinsRecebet = useRef(null);
  const buttonRefListaTransfer = useRef(null);

  const ListaChainsRef = useRef();
  const buttonRefListaChain = useRef(null);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaCoins && ListaCoinsRef.current && !ListaCoinsRef.current.contains(event.target) && !buttonRefListaCoinst.current.contains(event.target)) {
      setShowListaCoins(false);
    
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaCoins]);



useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaTransfer && ListaTransferRef.current && !ListaTransferRef.current.contains(event.target) && !buttonRefListaTransfer.current.contains(event.target)) {
      setShowListaTransfer(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaTransfer]);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (showListaChains && ListaChainsRef.current && !ListaChainsRef.current.contains(event.target) && !buttonRefListaChain.current.contains(event.target)) {
      setShowListaChains(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [showListaChains]);


const [selectedToken, setSelectedToken] = useState({
  flag: 'currency-flag-polygon',
  abbreviation: 'USDCNative',
  chain:'Polygon',
  name:'USDC'
});


const [selectedTokenRecebe, setSelectedTokenRecebe] = useState({
  flag: 'currency-flag-brl',
  abbreviation: 'BRL'
});

const [selectedChain, setSelectedChain] = useState({
  flag: 'currency-flag-polygon',
  abbreviation: 'Polygon'
});

const handleTokenSelect = (token) => {
  setMintValue('');
  setRecebeValue('');
  setQuoteResult('');
  setSelectedChain({
    flag: 'currency-flag-polygon',
    abbreviation: 'Polygon'
  })
  setSelectedToken(token);
  setShowListaCoins(false); // Close the dropdown after selection
  if (token.abbreviation === 'USD') {
    
  }

  
  
};


const handleListaSelect = (type) => {
  setMintValue('');
  setRecebeValue('');
  setShowListaTransfer(false);
  settransferType(type);

};



const handleTokenRecebeSelect = (token) => {
  setSelectedTokenRecebe(token);
  setQuoteResult('');
  setSelectedChain({
    flag: 'currency-flag-polygon',
    abbreviation: 'Polygon'
  })
  
  
  setShowListaCoinsRecebe(false); // Close the dropdown after selection
};


const handleChainSelect = (chain) => {
  setSelectedChain(chain);
  setQuoteResult('');
  console.log("chain: ", chain)

 
  setShowListaChains(false); // Close the dropdown after selection
};

const currencyFlagClass = `currency-flag ${selectedToken.flag}`;
const currencyFlagRecebeClass = `currency-flag ${selectedTokenRecebe.flag}`;
const currencyFlagPIXClass = `currency-flag currency-flag-pix`;
const currencyFlagPolygonClass = selectedChain.abbreviation === 'Polygon' ?  `currency-flag currency-flag-polygon` : selectedChain.abbreviation === 'XRP' ? `currency-flag currency-flag-xrp` : selectedChain.abbreviation === 'Tron' ? `currency-flag currency-flag-tron` :  selectedChain.abbreviation === 'CELO' ? `currency-flag currency-flag-celo` : `currency-flag currency-flag-moonbeam`   ;

 // Adicione um estado para salvar o result e gerenciar o cronômetro
 const [swapResult, setSwapResult] = useState(null);
 const [timer, setTimer] = useState(300); // 5 minutos em segundos

 // Função que controla o cronômetro
 useEffect(() => {
   if (swapResult && timer > 0) {
     const countdown = setInterval(() => {
       setTimer((prev) => prev - 1);
     }, 1000);

     return () => clearInterval(countdown);
   }

   if (timer === 0) {
     resetPaymentState(); // Voltar para o estado inicial
   }
 }, [swapResult, timer]);

 const resetPaymentState = () => {
   setSwapResult(null);
   setTimer(300);
   setMintValue('');
   setRecebeValue('');
   setPixKey('');
   setPixKeyValid(false);
   setMessage({ text: '', color: '' });
 };


const onSubmit = async (data) => {
  setIsLoading(true); // Definir como loading quando a requisição começar


  const cleanedPixKey = pixKeyType === 'CPF' || pixKeyType === 'CNPJ' || pixKeyType === 'Celular' ? pixKey.replace(/[-.()]/g, '').replace('R$ ', '').replace('+55', '').replace('/', '').replace(/ /g, '') : pixKey;
   
  setIsLoading(true)

  let amount = 0
  let fixOutput = false
  if (isMintInput) {
   amount = parseFloat((parseFloat(mintValue)*100).toFixed(0))
   fixOutput = false
  } else {
   amount = parseFloat((parseFloat(recebeValue)*100).toFixed(0))
   fixOutput = true
  }
  const quoteResult = await debouncedFastQuote.current(amount, selectedChain.abbreviation, selectedToken.abbreviation, 'BRLA', fixOutput);
  console.log("quoteResult: ", quoteResult)
  setQuoteResultFinal(quoteResult)
  const otp = data.code_1+data.code_2+data.code_3+data.code_4+data.code_5+data.code_6
  const result = await GenerateSwap(quoteResult?.token, cleanedPixKey, pixKeyType==='CPF' || pixKeyType==='CNPJ' ? pixKey :  CpfCnpj,fullName,selectedBank?.value,branchCode,accountNumber, otp);
  if (!result.error) {
    setSwapResult(result);
  } else {
   setMessage({text: result.error, color: 'red'}) 
  }
  


setIsLoading(false); // Definir como não loading quando a requisição terminar



}

const getClass = () => {
 
   if (selectedTokenRecebe.abbreviation === 'BRL' && selectedToken.abbreviation === 'BRL' && !pixKeyValid) {
    return 'brl-brl-payment';
    
  } else if (selectedTokenRecebe.abbreviation === DEFAULTCOIN && selectedToken.abbreviation === 'USD') {
    return 'brl-brl-payment'
  }
  else if (selectedTokenRecebe.abbreviation === 'BRL' && selectedToken.abbreviation === 'USD' && pixKeyValid) {
    return 'brl-usd-pix-payment'
      }
   else {
    return 'brl-usd-payment';
  }

}
const [pixInfo, setPixInfo] = useState(null);

async function GetPixInfo(pixKeyinput) {
  const response = await fetch(`${ENDPOINT}/pay-out/pix-info?pixKey=${pixKeyinput}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "MAINTENANCE-TOKEN":MANTEINENCETOKEN,
    },
    credentials:'include',
  });
  setIsLoading(false); // Definir como não loading quando a requisição terminar

  if (response.status === 200 || response.status === 201) {
    const responseData = await response.json();
    setPixInfo(responseData);

    
    // Não reabilite o botão
  } else {
    let responseData = {};
    if (response.headers.get('content-type').includes('application/json')) {
      responseData = await response.json();
    }
    // Trate erros aqui, caso necessário
   setMessage({text: responseData.error, color: 'red'})

}
}

function toTitleCase(str) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}



async function fastQuote(amount, chain, inputCoin, outputCoin, fixOutput) {
  try {
    setIsLoading(true)
    const resFastQuote = await fetch(`${ENDPOINT}/fast-quote?operation=pix-to-usd&amount=${amount}&chain=${chain}&inputCoin=${inputCoin}&outputCoin=${outputCoin}&fixOutput=${fixOutput}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN": MANTEINENCETOKEN
      },
    });
  
    if (resFastQuote.status === 401) {
      navigate('/login'); 
      return;
    }
    if (!resFastQuote.ok) { // Trata qualquer status diferente de 200-299
      console.log("PQP"); // Agora será exibido para qualquer erro HTTP
      setIsLoading(false)
      throw new Error(`HTTP error! Status: ${resFastQuote.status}`);
    }

    const data = await resFastQuote.json(); 
    setIsLoading(false)
    return data;
  } catch (error) {
    console.log("PQP")
      setMintValue('')
      setRecebeValue('')
      console.error('Error:', error.response?.data || error.message);
      setIsLoading(false)
      throw error;
  }

  
}
  
  
  
  async function GenerateSwap(token, pixKey,taxId,name,ispb,branchCode,accountNumber, otp) {
    try {
    const resFastQuote = await fetch(`${ENDPOINT}/pay-out/usd-to-pix/payment?otp=${otp}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN": MANTEINENCETOKEN
      },
      body: JSON.stringify({
        token: token,
        pixKey: pixKey,
        taxId: taxId,
        name: name,
        ispb: ispb,
        branchCode: branchCode,
        accountNumber: accountNumber

      }),
    });
  
    if (resFastQuote.status === 401) {
      navigate('/login'); 
      return;
    }
    const data = await resFastQuote.json(); 
    return data;
  } catch (error) {
    if (selectedChain.abbreviation==='Tron'&&selectedToken.abbreviation==='USDT'&&selectedTokenRecebe.abbreviation==='USDT') {
      setMintValue('')
      setRecebeValue('')
      setMessage({text: error.response?.data || error.message, color: 'red'})
    }
      console.error('Error:', error.response?.data || error.message);
      throw error;
  }
  }


  useEffect(() => {
  let interval;

  // Função para chamar fastQuote
  const updateFastQuote = async () => {
    if ((mintValue || recebeValue) && selectedToken && selectedTokenRecebe) {
      try {
        const fixOutput = !isMintInput; // Determina se está fixando o output
        const amount = fixOutput ? parseFloat((parseFloat( recebeValue) * 100).toFixed(0)) : parseFloat((parseFloat( mintValue) * 100).toFixed(0)) ;
        const result = await fastQuote(
          amount,
          selectedChain.abbreviation,
          selectedToken.abbreviation,
          'BRLA',
          fixOutput
        );
        setQuoteResult(result); // Atualiza o estado com os dados mais recentes
      } catch (error) {
        setMintValue('')
        setRecebeValue('')
        console.error("Erro ao atualizar fastQuote:", error);
      }
    }
  };

  if (mintValue || recebeValue) {
    // Inicia o intervalo de 5 segundos
    interval = setInterval(() => {
      updateFastQuote();
    }, 5000);
  }

  // Limpa o intervalo ao desmontar ou quando a condição deixa de ser verdadeira
  return () => {
    if (interval) clearInterval(interval);
  };
}, [mintValue, recebeValue, selectedToken, selectedTokenRecebe, selectedChain]);


useEffect(() => {
  let interval; // Variável para o intervalo
  const paymentOrderId = swapResult?.id; // ID salvo da paymentOrder
  console.log("paymentOrderId: ", paymentOrderId)

  const fetchPaymentStatus = async () => {
    try {
      const response = await fetch(`${ENDPOINT}/pay-out/usd-to-pix/payment/history?id=${paymentOrderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "MAINTENANCE-TOKEN": MANTEINENCETOKEN,
        },
        credentials: 'include',
      });

      if (!response.ok) {
        console.error("Erro ao verificar status do pagamento:", response.status);
        return;
      }

      const data = await response.json();
      console.log("data: ", data.paymentUsdToPixLogs[0].status)
      
      // Verifica o status da resposta
      if (data.paymentUsdToPixLogs[0].status === 'PAID') {
        console.log("bora caceta")
        setCompleted(true); // Atualiza para o estado de sucesso
        clearInterval(interval); // Para o intervalo
      }
    } catch (error) {
      console.error("Erro ao verificar status do pagamento:", error);
    }
  };

  if (swapResult && paymentOrderId && !completed) {
    // Configura o intervalo para verificar o status a cada 5 segundos
    interval = setInterval(fetchPaymentStatus, 5000);
  }

  // Cleanup: limpa o intervalo ao desmontar ou quando a transação é concluída
  return () => {
    if (interval) clearInterval(interval);
  };
}, [swapResult, completed]); // Dependências incluem swapResult e completed



    return (
<>
        {!completed ? (<form style={{zIndex:100000}} onSubmit={handleSubmit(onSubmit)}>
         <div style={{marginTop:'-140px'}} className={`${resets.storybrainResets} ${classes.root}`}>
           
         {swapResult && !completed ? (
    <div
    className="qr-code-screen"
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "40px",
      backgroundColor: "#fff",
      borderRadius: "15px",
      textAlign: "center",
      gap: "20px",
    }}
  >
    {/* Cronômetro */}
    <p
      style={{
        fontSize: "24px",
        fontWeight: "bold",
        color: "#000",
        marginBottom: "20px",
      }}
    >
      {t("time_remaining")}: {Math.floor(timer / 60)}:
      {String(timer % 60).padStart(2, "0")}
    </p>
  
    {/* Título */}
    <h2 style={{ fontSize: "22px", margin: "0 0 10px 0" }}>
      Wallet QR Code
    </h2>
  
    {/* QR Code */}
    <QRCode
      value={users?.wallets?.evm}
      size={200}
      bgColor="transparent"
      fgColor="#000000"
      renderAs="svg"
      imageSettings={{
        src: "https://brla.digital/static/media/logo_b.png",
        x: null,
        y: null,
        height: 24,
        width: 24,
        excavate: true,
      }}
    />
  
    {/* Instruções */}
    <p style={{ fontSize: "16px", margin: "1px 0" }}>
      Wallet: {users?.wallets?.evm}
    </p>

    <p style={{ fontSize: "16px", margin: "1px 0" }}>
      Token: {selectedToken.abbreviation}
    </p>
  
    {/* ID da Transação */}
    <p style={{ fontSize: "16px", margin: "1px 0" }}>
      {t("usd_amount")}: {quoteResultFinal.amountUsd}
    </p>
  
    {/* Valor Total */}
    <p style={{ fontSize: "16px", margin: "1px 0" }}>
      {t("brl_amount")}: {quoteResultFinal.amountBrl}
    </p>
  
    {/* Botão */}
    <button
      onClick={resetPaymentState}
      style={{
        backgroundColor: "#00dc84",
        color: "#fff",
        border: "none",
        borderRadius: "8px",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: "pointer",
      }}
    >
      {t("cancel")}
    </button>
  </div>) :

           (   <div className={classes.section}>
              {showListaCoins && <ListaCoinsPayment ref={ListaCoinsRef} onTokenSelect={handleTokenSelect} left='0px' top='100px'   />}
                <div className={classes.divH2dC43aaa00}>
     
                  <div className={classes.frame9}>
               
                    <div className={classes.labelVoceEnviaExatamente}>Você envia exatamente</div>
                  
                    </div>
                  
                  <div className={classes.divTwMoneyInput}>
                    <div className={classes.pseudo}>
                    <input
                    onChange={handleMintInputChange}
                    value={
                      isNaN(mintValue) ? "Carregando..." : mintValue
                    }
                    placeholder='1000.00' className={`limpar-input ${classes._10000}`} />
        
                    </div>
                    <button 
                  
                    type='button' className={`${classes.divDFlex} limpar-botao`}>
                    <div className={classes.iCurrencyFlag2}>
  <div   ref={buttonRefListaCoinst}
                    onClick={() => {
                        setMintValue('');
                        setRecebeValue('');
                        setShowListaCoins(!showListaCoins);
                        setPixKeyType('');
                        setPixKeyValid(false);
                        OnPixKeyValid(false);
                        onPixKeyTypeChange('CPF');
                      }} className={classes.brlSvg2}>
    <>
      {selectedToken.chain === 'Polygon' ? (
        <svg
        version="1.0"
        id="katman_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 800 600"
        style={{ enableBackground: 'new 0 0 800 600' }}
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`
            .st0 { fill: #8247E5; }
            .st1 { clip-path: url(#SVGID_00000170985874484226784740000010197121833159053193_); }
            .st2 { fill: #FFFFFF; }
          `}
        </style>
        <path
          className="st0"
          d="M399.8,45.4L399.8,45.4C540.2,45.4,654,159.2,654,299.6l0,0c0,140.4-113.8,254.1-254.1,254.1l0,0
          c-140.4,0-254.1-113.8-254.1-254.1l0,0C145.7,159.2,259.5,45.4,399.8,45.4z"
        />
        <g>
          <defs>
            <rect id="SVGID_1_" x="231.5" y="155.3" width="333.1" height="291.9" />
          </defs>
          <clipPath id="SVGID_00000119824040907289936360000005545950198423505064_">
            <use
              xlinkHref="#SVGID_1_"
              style={{ overflow: 'visible' }}
            />
          </clipPath>
          <g style={{ clipPath: 'url(#SVGID_00000119824040907289936360000005545950198423505064_)' }}>
            <path
              className="st2"
              d="M483.1,244.2c-6.1-3.5-13.9-3.5-20.8,0L413.7,273l-33,18.3L333,320c-6.1,3.5-13.9,3.5-20.8,0l-37.3-22.7
                c-6.1-3.5-10.4-10.5-10.4-18.3v-43.6c0-7,3.5-13.9,10.4-18.3l37.3-21.8c6.1-3.5,13.9-3.5,20.8,0l37.3,22.7
                c6.1,3.5,10.4,10.5,10.4,18.3v28.8l33-19.2v-29.6c0-7-3.5-13.9-10.4-18.3l-69.4-41c-6.1-3.5-13.9-3.5-20.8,0L242,198.9
                c-6.9,3.5-10.4,10.5-10.4,17.4v81.9c0,7,3.5,13.9,10.4,18.3l70.3,41c6.1,3.5,13.9,3.5,20.8,0l47.7-27.9l33-19.2l47.7-27.9
                c6.1-3.5,13.9-3.5,20.8,0l37.3,21.8c6.1,3.5,10.4,10.5,10.4,18.3v43.6c0,7-3.5,13.9-10.4,18.3l-36.4,21.8
                c-6.1,3.5-13.9,3.5-20.8,0L425,384.5c-6.1-3.5-10.4-10.5-10.4-18.3v-27.9l-33,19.2v28.8c0,7,3.5,13.9,10.4,18.3l70.3,41
                c6.1,3.5,13.9,3.5,20.8,0l70.3-41c6.1-3.5,10.4-10.5,10.4-18.3v-82.8c0-7-3.5-13.9-10.4-18.3L483.1,244.2z"
            />
          </g>
        </g>
      </svg>
      ) : selectedToken.chain === 'Ethereum' ? (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Ethereum"
        role="img"
        viewBox="0 0 512 512"
      >
        <rect width="512" height="512" rx="15%" fill="#ffffff" />
        <path fill="#3C3C3B" d="m256 362v107l131-185z" />
        <path fill="#343434" d="m256 41l131 218-131 78-132-78" />
        <path fill="#8C8C8C" d="m256 41v158l-132 60m0 25l132 78v107" />
        <path fill="#141414" d="m256 199v138l131-78" />
        <path fill="#393939" d="m124 259l132-60v138" />
      </svg>       )
      
      : selectedToken.abbreviation === 'USDT' ? 

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="24" height="24"><path d="M1000 0c552.26 0 1000 447.74 1000 1000s-447.76 1000-1000 1000S0 1552.38 0 1000 447.68 0 1000 0" fill="#53ae94"/><path d="M1123.42 866.76V718h340.18V491.34H537.28V718H877.5v148.64C601 879.34 393.1 934.1 393.1 999.7s208 120.36 484.4 133.14v476.5h246V1132.8c276-12.74 483.48-67.46 483.48-133s-207.48-120.26-483.48-133m0 225.64v-.12c-6.94.44-42.6 2.58-122 2.58-63.48 0-108.14-1.8-123.88-2.62v.2C633.34 1081.66 451 1039.12 451 988.22S633.36 894.84 877.62 884v166.1c16 1.1 61.76 3.8 124.92 3.8 75.86 0 114-3.16 121-3.8V884c243.8 10.86 425.72 53.44 425.72 104.16s-182 93.32-425.72 104.18" fill="#fff"/></svg>

      : <div><img style={{width:'24px', height:'24px'}} src='https://brla.digital/favicon.png'/></div>}
    </>
  </div>

  
</div>
                    </button>
                  </div>
                  {(selectedTokenRecebe.abbreviation !== selectedToken.abbreviation ) && !(selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USD' ) && !(selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL' ) && !(selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT' ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD' )  ? (
                                  <ul className="tw-calculator-breakdown " style={{marginBottom:'-20px'}}>
                                    < li style={{width:'350px', marginTop:'-15px'}}>
                                      <span className="tw-calculator-breakdown__icon">
                                        <span>
                                          <span>–</span>
                                        </span>
                                      </span>
                                      <div  className="tw-calculator-breakdown__content">
                                        <span style={{ fontSize:"14px", color:'black'}}  className=" tw-calculator-breakdown-item__value">
                                        {
  isNaN(quoteResult?.baseFee) ? (
    "Carregando..."
  ) : (
    
    parseFloat(quoteResult?.baseFee).toFixed(2) +   (selectedTokenRecebe.abbreviation ==='USDC' ? ' USD' : " BRL")
  )
}
                                        </span>
                                        <span className="tw-calculator-breakdown-item__right tw-calculator-breakdown-item__label">
                                          <span data-tracking-id="calculator-payment-select">
                                            <div className="np-select btn-group">
                                              <button
                                                className="btn btn-sm np-btn np-btn-sm  np-text-body-large limpar-botao"
                                                id="np-select-443252"
                                                role="combobox"
                                                aria-controls="np-select-443252-listbox"
                                                aria-expanded="false"
                                                aria-autocomplete="none"
                                                type="button"
                                                
                                              >
                                                <span style={{ fontSize:"14px", border:'none', color:'black'}} className="np-text-body-large text-ellipsis">
                                                  Tarifa total
                                                  custo
                                                </span>
                                                <span
                                                  className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                                  aria-hidden="true"
                                                  role="presentation"
                                                  data-testid="chevron-up-icon"
                                                >
                                                                                             </span>
                                              </button>
                                            </div>
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                
             
                                    < li style={{width:'350px', marginTop:'-10px'}}>
                                      <span className="tw-calculator-breakdown__icon">
                                        <span>
                                          <span>÷</span>
                                        </span>
                                      </span>
                                      <div  className="tw-calculator-breakdown__content">
                                        <span  className="tw-calculator-breakdown-item__left tw-calculator-breakdown-item__value">
                                          <a
                                            role="button"
                                            className="btn-unstyled limpar-botao"
                                            tabIndex={0}
                                            style={{border:'none'}}
                                          >
                                            <span style={{ fontSize:"14px", color:'black', textDecoration:'none'}} className="tw-calculator-breakdown-rate__value limpar-borda">
                                            {
  isNaN(quoteResult?.baseFee) ? (
    "Carregando..."
  ) : (
    parseFloat(quoteResult?.baseFee).toFixed(4)
  )
}
                                            </span>
                                          </a>
                                        </span>
                                        <span className="tw-calculator-breakdown-item__right tw-calculator-breakdown-item__label">
                                          <span className="np-popover">
                                            <span className="d-inline-block">
                                              <button
                                                className="btn btn-md np-btn np-btn-md   btn-unstyled limpar-botao "
                                                type="button"
                                                style={{
                                                  height: "auto",
                                                  verticalAlign: "bottom"
                                                }}
                                              >
                                                <span style={{ fontSize:"14px", color:'black'}} data-tracking-id="calculator-fixed-rate-tooltip">
                                                  Taxa de câmbio comercial
                                                </span>{" "}
                                                <span
                                                  style={{ whiteSpace: "nowrap" }}
                                                >
                                                 
                                                </span>
                                              </button>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                  </ul> ) :(<div></div>)}
                </div>
                <div className={classes.divH2dD16ce6e1}>
                  <div className={classes.labelBeneficiarioRecebe}>Beneficiário recebe</div>
               
                  {showListaCoinsRecebe && <ListaCoinsRecebe ref={ListaCoinsRecebeRef} onTokenRecebeSelect={handleTokenRecebeSelect} left='0px' top='200px' position='absolute' />}
                  
                  <div className={classes.divTwMoneyInput2}>
                  
                    <div className={classes.pseudo2}>
                   
                      <input  placeholder={
                                                  (selectedToken.abbreviation === 'USD' && (selectedTokenRecebe.abbreviation === 'USDC' || selectedTokenRecebe.abbreviation === 'USDT') )
                                                    ? '1000.00'
                                                    : ((selectedTokenRecebe.abbreviation === 'USDT' || selectedTokenRecebe.abbreviation === 'USDC')  && selectedToken.abbreviation === 'BRL')
                                                        ? (isNaN(quoteResult?.amountUsd) ? "Carregando..." : (quoteResult?.amountUsd / 100).toFixed(2))
                                                        : '999.25'
                                                }
                                                onChange={handleRecebeInputChange}
                                                value={isNaN(recebeValue) ? "Carregando..." : recebeValue}
        
                                                className={`${classes._99925} limpar-input`}/>
                    </div>
                    <button
                    

                    type='button' className={`${classes.divDFlex2} limpar-botao`}>
                    <div  className={classes.iCurrencyFlag2}>
  <div ref={buttonRefListaCoinsRecebet}
                    onClick={() => {
                        setMintValue('');
                        setRecebeValue('');
                          setShowListaCoinsRecebe(!showListaCoinsRecebe);
                          setPixKeyType('');
                          setPixKeyValid(false);
                          OnPixKeyValid(false);
                          onPixKeyTypeChange('CPF');
          
                        }} className={classes.brlSvg2}>
    <>
      {selectedTokenRecebe.abbreviation === 'BRL' ? (
        <svg preserveAspectRatio='none' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g clipPath='url(#clip0_62_81)'>
            <mask
              id='mask0_62_81'
              style={{
                maskType: 'luminance',
              }}
              maskUnits='userSpaceOnUse'
              x={0}
              y={0}
              width={24}
              height={24}
            >
              <path d='M24 0H0V24H24V0Z' fill='white' />
            </mask>
            <g mask='url(#mask0_62_81)'>
              <path
                d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0C5.37259 0 0 5.37259 0 12C0 18.6274 5.37259 24 12 24Z'
                fill='#005B13'
              />
              <path d='M1.5 12.0001L12 5.00006L22.5 12.0001L12 19.0001L1.5 12.0001Z' fill='#FFD400' />
              <path
                d='M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51473 14.4853 7.5 12 7.5C9.51473 7.5 7.5 9.51473 7.5 12C7.5 14.4853 9.51473 16.5 12 16.5Z'
                fill='#231D9A'
              />
              <path
                d='M9.49957 10.4999C12.1496 10.4999 14.5496 11.5749 16.2996 13.2749C16.3496 13.0999 16.3996 12.8999 16.4246 12.6999C14.5996 11.0249 12.1746 9.99994 9.49957 9.99994C8.9496 9.99994 8.4246 10.0499 7.92458 10.1249C7.84958 10.2999 7.77457 10.4749 7.69957 10.6749C8.2746 10.5499 8.8746 10.4999 9.49957 10.4999Z'
                fill='white'
              />
            </g>
          </g>
          <defs>
            <clipPath id='clip0_62_81'>
              <rect width={24} height={24} fill='white' />
            </clipPath>
          </defs>
        </svg>
      ) : selectedTokenRecebe.abbreviation === 'USDT' ? (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2000 2000"
        width={24}
        height={24}
      >
        <path
          d="M1000 0c552.26 0 1000 447.74 1000 1000s-447.76 1000-1000 1000S0 1552.38 0 1000 447.68 0 1000 0"
          fill="#53ae94"
        />
        <path
          d="M1123.42 866.76V718h340.18V491.34H537.28V718H877.5v148.64C601 879.34 393.1 934.1 393.1 999.7s208 120.36 484.4 133.14v476.5h246V1132.8c276-12.74 483.48-67.46 483.48-133s-207.48-120.26-483.48-133m0 225.64v-.12c-6.94.44-42.6 2.58-122 2.58-63.48 0-108.14-1.8-123.88-2.62v.2C633.34 1081.66 451 1039.12 451 988.22S633.36 894.84 877.62 884v166.1c16 1.1 61.76 3.8 124.92 3.8 75.86 0 114-3.16 121-3.8V884c243.8 10.86 425.72 53.44 425.72 104.16s-182 93.32-425.72 104.18"
          fill="#fff"
        />
      </svg>
      
      ) : selectedTokenRecebe.abbreviation === 'USDC' ? (
        <svg data-name="86977684-12db-4850-8f30-233a7c267d11" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000">
        <path d="M1000 2000c554.17 0 1000-445.83 1000-1000S1554.17 0 1000 0 0 445.83 0 1000s445.83 1000 1000 1000z" fill="#2775ca"/>
        <path d="M1275 1158.33c0-145.83-87.5-195.83-262.5-216.66-125-16.67-150-50-150-108.34s41.67-95.83 125-95.83c75 0 116.67 25 137.5 87.5 4.17 12.5 16.67 20.83 29.17 20.83h66.66c16.67 0 29.17-12.5 29.17-29.16v-4.17c-16.67-91.67-91.67-162.5-187.5-170.83v-100c0-16.67-12.5-29.17-33.33-33.34h-62.5c-16.67 0-29.17 12.5-33.34 33.34v95.83c-125 16.67-204.16 100-204.16 204.17 0 137.5 83.33 191.66 258.33 212.5 116.67 20.83 154.17 45.83 154.17 112.5s-58.34 112.5-137.5 112.5c-108.34 0-145.84-45.84-158.34-108.34-4.16-16.66-16.66-25-29.16-25h-70.84c-16.66 0-29.16 12.5-29.16 29.17v4.17c16.66 104.16 83.33 179.16 220.83 200v100c0 16.66 12.5 29.16 33.33 33.33h62.5c16.67 0 29.17-12.5 33.34-33.33v-100c125-20.84 208.33-108.34 208.33-220.84z" fill="#fff"/>
        <path d="M787.5 1595.83c-325-116.66-491.67-479.16-370.83-800 62.5-175 200-308.33 370.83-370.83 16.67-8.33 25-20.83 25-41.67V325c0-16.67-8.33-29.17-25-33.33-4.17 0-12.5 0-16.67 4.16-395.83 125-612.5 545.84-487.5 941.67 75 233.33 254.17 412.5 487.5 487.5 16.67 8.33 33.34 0 37.5-16.67 4.17-4.16 4.17-8.33 4.17-16.66v-58.34c0-12.5-12.5-29.16-25-37.5zM1229.17 295.83c-16.67-8.33-33.34 0-37.5 16.67-4.17 4.17-4.17 8.33-4.17 16.67v58.33c0 16.67 12.5 33.33 25 41.67 325 116.66 491.67 479.16 370.83 800-62.5 175-200 308.33-370.83 370.83-16.67 8.33-25 20.83-25 41.67V1700c0 16.67 8.33 29.17 25 33.33 4.17 0 12.5 0 16.67-4.16 395.83-125 612.5-545.84 487.5-941.67-75-237.5-258.34-416.67-487.5-491.67z" fill="#fff"/>
      </svg>
      
      
      )

      :
      
      <div><img style={{width:'24px', height:'24px'}} src='https://brla.digital/favicon.png'/></div>}
    </>
  </div>
</div>


                    </button>
                  </div>
                </div>
              <>
                {(selectedTokenRecebe.abbreviation === 'BRL') ? (
                    
    <div className={classes.divH2dBd04bbe1}>
      <div className={classes.labelChavePIX}>Chave PIX</div>
      <div className={classes.divTwMoneyInput3}>
        <div className={classes.pseudo3}>
          <input
            onChange={handlePixKeyChange}
            onBlur={handlePixKeyFormat}
            value={pixKey}
            placeholder='Digite sua chave PIX' 
            className={`${classes.digiteSuaChavePIX} limpar-input`}
          />
        </div>
        <div className={classes.pseudo4}>
          <img style={{width:'25px'}} src={Pix}/>
        </div>
      </div>
    </div>

    

   ) :
   
    
      ( selectedTokenRecebe.abbreviation === 'USDT' ||  selectedTokenRecebe.abbreviation === 'BRLA' || selectedTokenRecebe.abbreviation === 'USDC'  )   ?

      <div className={classes.divH2dBd04bbe1}>
      <div className={classes.labelChavePIX}>Endereço da wallet</div>
      <div className={classes.divTwMoneyInput3}>
        <div className={classes.pseudo3}>
          <input
             onChange={handleWalletChange}
             value={walletValue}
            placeholder='0xc37...3887' 
            className={`${classes.digiteSuaChavePIX} limpar-input`}
          />
        </div>
        <div className={classes.pseudo4}>
          <img style={{width:'25px'}} src='https://brla.digital/static/media/polygon-icon.png'/>
        </div>
      </div>
    </div> : <div></div>
   
}
</>

{ !(pixKeyType==='CPF' || pixKeyType==='CNPJ') && selectedTokenRecebe.abbreviation==='BRL' && pixKeyValid ?  
      <div style={{marginBottom:'20px'}} className={classes.divH2dBd04bbe1}>
        <div className={classes.labelChavePIX}>CPF/CNPJ do beneficiário</div>
        <div className={classes.divTwMoneyInput3}>
          <div className={classes.pseudo3}>
            <input
              onChange={handleCpfCnpjChange}
              onBlur={handleCpfCnpjFormat}
              value={CpfCnpj}
              placeholder='Digite o CPF/CNPJ' 
              className={`${classes.digiteSuaChavePIX} limpar-input`}
            />
          </div>
          <div className={classes.pseudo4}>
            <img style={{width:'25px'}} src={Pix}/>
          </div>
        </div>
      </div> 
      : 
      <div></div>
    } 
 { (selectedTokenRecebe.abbreviation==='BRL' && pixKey!=='' && pixKeyValid) ? (
      <>
 

        <div className="" style={{marginTop:'-35px'}}>
          <strong className="tw-review-details-card__title">
            <small className="ng-binding">
              {" "} Dados do beneficiário {" "}
            </small>
          </strong>
        </div>
        
        <p
          ng-repeat="detail in $ctrl.contact.display.details track by $index"
          className="tw-review-element  ng-scope"
          style={{}}
        >
          <small style={{fontSize:'14px'}} className="tw-review-element__name ng-binding">
            Nome do titular da conta
          </small>
          <span style={{fontSize:'14px', marginTop:"-2px"}} className="tw-review-element__value text-primary ng-binding">
            {toTitleCase(pixInfo?.name || '')}
          </span>
        </p>
        

        

        <p
          className="m-b-0 tw-review-element ng-hide"
          ng-show="$ctrl.deliveryMethod === 'LINK'"
       
        >
          <small style={{fontSize:'14px'}} className="tw-review-element__name ng-binding">
            Banco
          </small>
          <span style={{fontSize:'14px', marginTop:"-2px"}} className="tw-review-element__value text-primary ng-binding">
            {toTitleCase(pixInfo?.bankName || '')}
          </span>
        </p>
      
      </>
    ) : (
      <div></div>
    )
    }

{(selectedTokenRecebe.abbreviation !== selectedToken.abbreviation ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD' ) && !(selectedTokenRecebe.abbreviation==='BRLA' && selectedToken.abbreviation==='BRL' ) && !(selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USD' ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USDT' ) && !(selectedTokenRecebe.abbreviation==='USDC' && selectedToken.abbreviation==='USDT' )  ? (


<div style={{
  fontSize: "14px", 
  marginTop: selectedTokenRecebe.abbreviation === 'USDT' ? '-35px' : '-10px',
  marginBottom:'20px',
}} className="tw-calculator-effective-rate">
                                    <strong>1 BRL = {isNaN(quoteResult?.amountBrl) || isNaN(quoteResult?.amountUsd)
  ? "Carregando... "
  : (parseFloat(quoteResult?.amountBrl) / parseFloat(quoteResult?.amountUsd)).toFixed(4)
+" BRL"}
 </strong>{" "}
  <span className="np-popover">
    <span className="d-inline-block">
      <button
        className="limpar-botao"
        type="button"
        style={{
          height: "auto",
          verticalAlign: "bottom",
          fontSize:'14px',
          color:'black',
          marginLeft:'25px',
          marginTop:"4px"
        }}
      >
        Câmbio efetivo (VET)
      </button>
    </span>
  </span>
</div> ) :(<div></div>)}


{users?.twoFAActivated &&
                            <>

<div style={{marginTop:'-40px'}} className={classes.divH2dBd04bbe1}>
      <div className={classes.labelChavePIX}>2FA</div>

    
      <div className="verify-input-container"  >
                    <div className="code-input-container" style={{ display: 'flex', gap: '20px' }}>
                    <div className={`code-section${codeoneActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_2');
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                          }}
                          onFocus={() => {
                            SetcodeoneActive(true);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_1', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codetwoActive ? ' active' : ''}`}>

                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_3');
                            SetcodetreeActive(true);
                            SetcodetwoActive(false);
                          }}
                          onFocus={() => {
                            SetcodetwoActive(true);
                            SetcodeoneActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_2', { required: true })}
                        />

                      </div>
                      <div className={`code-section${codetreeActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_4');
                            SetcodefourActive(true);
                            SetcodetreeActive(false);
                          }}
                          onFocus={() => {
                            SetcodetreeActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_3', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefourActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_5');
                            SetcodefiveActive(true);
                            SetcodefourActive(false);
                          }}
                          onFocus={() => {
                            SetcodefourActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefiveActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_4', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codefiveActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onInput={() => {
                            setFocus('code_6');
                            SetcodesixActive(true);
                            SetcodefiveActive(false);
                          }}
                          onFocus={() => {
                            SetcodefiveActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodesixActive(false);
                          }}
                          {...register('code_5', { required: true })}
                        />
                      </div>
                      <div className={`code-section${codesixActive ? ' active' : ''}`}>
                        <input
                          type="tel"
                          className="code-input"
                          maxLength={1}
                          defaultValue=""
                          onFocus={() => {
                            SetcodesixActive(true);
                            SetcodeoneActive(false);
                            SetcodetwoActive(false);
                            SetcodetreeActive(false);
                            SetcodefourActive(false);
                            SetcodefiveActive(false);
                          }}
                          {...register('code_6', { required: true })}
                        />
                      </div>
                    </div>
                  </div>
    
    </div>
                             </>}
        
                <div className={classes.alert}>
                  <div className={classes.spanStatusCircle}>
                    <div className={classes.sVG}>
                    <svg preserveAspectRatio='none' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M14.8573 13.1427H17.1427V26.2853H14.8573V13.1427Z' fill='white' />
            <path
              d='M16 10.2853C17.2622 10.2853 18.2853 9.26215 18.2853 8C18.2853 6.73784 17.2622 5.71467 16 5.71467C14.7378 5.71467 13.7147 6.73784 13.7147 8C13.7147 9.26215 14.7378 10.2853 16 10.2853Z'
              fill='white'
            />
          </svg>
                    </div>
                  </div>


                  <div  className={classes.divAlert__message}>
                    <div style={{display:"inline"}}  className={classes.aoContinuarVoceAceitaOsTermosD}>
                      Ao continuar, você aceita os Termos de Uso da nossa empresa.
                      <span style={{display:"inline"}}><a href='https://brla.digital/terms-and-services-pt.pdf' target='_blank'>Saiba mais</a>
                  
                      </span>
                
                    </div>
                    
                  </div>
                </div>
                {isInvalid &&
                <div className={classes.oValorDeveSerMaiorQueR75EMenor}>
                </div>}
        
                {message && message.text &&
                <div className={classes.oValorDeveSerMaiorQueR75EMenor}>
                  {message.text}
                </div>}
        
                <button disabled={false } className={classes.button}>
                  <div className={classes.confirmarTransferencia}> {isLoading ? "Carregando..." : "Confirmar transferência"}</div>
                </button>
               
              </div> )}
            
            </div>
        
            </form>) :   <div style={{display:"flex", justifyContent:"center", paddingTop:"80px", paddingBottom:"100px"}} className="row mx-auto">
                                                
                               
                                 
            <img style={{width:'200px', height:'200px'}} src={Checked} />
            
            <p style={{marginTop:"20px", marginBottom:'10px', width:'400px'}}>Sua transação será processada, por favor aguarde e acompanhe o status em "Transações".</p>
          </div> }
          </>
)};
    export default PaymentMobile;